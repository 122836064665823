import React, { useEffect } from "react";
import style from "./style.module.scss";
import { format } from "date-fns";
import { de } from "date-fns/locale";
import { useAppDispatch, useAppSelector } from "../../store";
import { useParams } from "react-router-dom";
import {
  AdminContentWrapper,
  AdminDetailInfoItem,
  AdminDetailPageHeader,
  AdminDetailsPageItemName,
  Button,
  Loader,
} from "../../components";
import { setDataLoading } from "../../store/slices/admin";
import {
  clearAdminSepaItem,
  getAdminSepaItemById,
  updateStatusById,
} from "../../store/slices/sepa";
import { statuses } from "../AdminSperrmuelanmeldungen";
import transformHexToRgba from "../../util/transformHexToRgba";
import appConfig from "../../util/appConfig";

const payerTypes: { [key: string]: string } = {
  Eigentümer: "Eigentümer",
  abweichendenZahlungsempfänder:
    appConfig.logo === "calw"
      ? "Abweichender Kontoinhaber"
      : "Abweichender Rechnungsempfänger",
  Regulierer: "Regulierer",
};

const AdminSepaById = () => {
  const dispatch = useAppDispatch();
  const { itemId } = useParams();

  const { isAdminLoaderActive } = useAppSelector((state) => state.admin);
  const { isSepaDataLoading, sepaItem } = useAppSelector(
    (state) => state.sepa.admin
  );

  useEffect(() => {
    if (itemId) {
      dispatch(getAdminSepaItemById(itemId));
    }
    return () => {
      dispatch(clearAdminSepaItem());
      dispatch(setDataLoading(false));
    };
  }, []);

  const generateEnvTitle = (type: string) => {
    switch (type) {
      case "lra": {
        return "Objekt-ID";
      }
      case "calw": {
        return "Kassenzeichen";
      }
      case "viersen": {
        return "Kassenzeichen";
      }
      default: {
        return "PK-ID";
      }
    }
  };

  const items = [
    {
      title: "",
      items: [
        {
          rowTitle: "Status",
          rowInfo: statuses[sepaItem?.status!],
        },
        sepaItem?.errorMessage
          ? {
              rowTitle: "Fehler Meldung",
              rowInfo: sepaItem?.errorMessage,
            }
          : {},
        {
          rowTitle: "Datum (Meldung)",
          rowInfo: sepaItem?.createdAt
            ? format(new Date(sepaItem?.createdAt), "dd.MM.yyyy, HH:mm", {
                locale: de,
              })
            : "",
        },
      ],
    },
    {
      title:
        appConfig.logo === "calw"
          ? "Kontaktdaten"
          : "Kontaktdaten des Briefempfängers",
      items: [
        {
          rowTitle: generateEnvTitle(appConfig.logo),
          rowInfo: `${sepaItem?.idNumber}`,
          isActive: appConfig.logo === "calw",
        },
        {
          rowTitle: "Name",
          rowInfo: `${sepaItem?.ownerSurname}${
            sepaItem?.ownerName ? ", " + sepaItem?.ownerName : ""
          }`,
          isActive: appConfig.logo === "calw",
        },
        {
          rowTitle: "EMail",
          rowInfo: sepaItem?.email ? sepaItem?.email : "",
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: sepaItem?.phoneNumber ? `+${sepaItem?.phoneNumber}` : "",
        },
      ],
    },
    {
      title: "Kontaktdaten des Kontoinhabers",
      items: [
        {
          rowTitle: generateEnvTitle(appConfig.logo),
          rowInfo: `${sepaItem?.idNumber}`,
          isActive: appConfig.logo !== "calw",
        },
        {
          rowTitle: "Name",
          rowInfo: `${sepaItem?.userSurname} ${
            sepaItem?.username ? ", " + sepaItem?.username : ""
          }`,
          // isActive: appConfig.logo !== "calw",
        },
        {
          rowTitle: "Strasse",
          rowInfo: `${sepaItem?.street} ${sepaItem?.homeNumber} ${
            sepaItem?.homeNumberZusatz ? sepaItem?.homeNumberZusatz : ""
          }`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${sepaItem?.country ? sepaItem.country + " " : ""}${
            sepaItem?.postCode
          } ${sepaItem?.place}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: sepaItem?.contactPhoneNumber
            ? "+" + sepaItem?.contactPhoneNumber
            : "",
        },
      ],
    },
    {
      title: "Kontaktdaten des Zahlungspflichtigen/Regulierers",
      items: [
        {
          rowTitle: "Name",
          rowInfo: `${sepaItem?.reguliererSurname} ${
            sepaItem?.reguliererName ? ", " + sepaItem?.reguliererName : ""
          }`,
        },
        {
          rowTitle: "Strasse",
          rowInfo: `${sepaItem?.reguliererStreet} ${
            sepaItem?.reguliererHomeNumber
          } ${
            sepaItem?.reguliererHomeNumberZusatz
              ? sepaItem?.reguliererHomeNumberZusatz
              : ""
          }`,
        },
        {
          rowTitle: "Ort",
          rowInfo: `${sepaItem?.country ? sepaItem.country + " " : ""}${
            sepaItem?.reguliererPostCode
          } ${sepaItem?.reguliererPlace}`,
        },
        {
          rowTitle: "Telefonnummer",
          rowInfo: sepaItem?.reguliererContactPhoneNumber
            ? "+" + sepaItem?.reguliererContactPhoneNumber
            : "",
        },
      ],
      isActive: ["calw"].includes(appConfig.logo),
    },
    {
      title: "Bankdaten",
      items: [
        {
          rowTitle: "Kontoinhaber",
          rowInfo: sepaItem?.payerType ? payerTypes[sepaItem?.payerType!] : "",
        },
        {
          rowTitle: "IBAN",
          rowInfo: sepaItem?.iban || "",
        },
        {
          rowTitle: "BIC",
          rowInfo: sepaItem?.bic || "",
        },
        {
          rowTitle: "Mandatsnummer",
          rowInfo: sepaItem?.mandatNr || "",
          isActive: ["viersen"].includes(appConfig.logo),
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <AdminDetailPageHeader isBtnAvailable={false} />
      <AdminContentWrapper>
        <AdminDetailsPageItemName text="Detailinformationen" />
        {isSepaDataLoading ? null : !sepaItem ? null : <div></div>}
        <div>
          {items
            .filter((el) => el.isActive === undefined || el.isActive)
            .map((el, idx) => {
              return (
                <AdminDetailInfoItem
                  items={el.items}
                  title={el.title}
                  key={idx}
                />
              );
            })}
          {["calw"].includes(appConfig.logo) &&
          sepaItem?.payerType !== "abweichendenZahlungsempfänder" &&
          sepaItem?.status === "accepted" ? null : (
            <>
              {["calw"].includes(appConfig.logo) &&
              (sepaItem?.status === "inWork" ||
                sepaItem?.status === "check") ? (
                <div
                  style={{
                    marginBottom: "20px",
                    fontWeight: 500,
                    fontSize: "18px",
                    color: "#353b3f",
                    fontFamily: "Nunito",
                  }}
                >
                  {sepaItem?.status === "inWork" ? (
                    <span>
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        Hinweis:
                      </span>{" "}
                      Beim Akzeptieren wird eine automatische Verarbeitung der
                      Daten nach CityData erfolgen!
                    </span>
                  ) : (
                    <span>
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        Hinweis:
                      </span>{" "}
                      Beim Akzeptieren wird{" "}
                      <span
                        style={{
                          fontWeight: 800,
                        }}
                      >
                        KEINE
                      </span>{" "}
                      automatische Verarbeitung der Daten nach CityData
                      erfolgen. Die Bearbeitung muss manuell stattfinden!
                    </span>
                  )}
                </div>
              ) : null}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {["calw"].includes(appConfig.logo) ? (
                  <Button
                    text={
                      sepaItem?.payerType === "abweichendenZahlungsempfänder"
                        ? "Prüfen"
                        : "Offen"
                    }
                    width="200"
                    isOutlined
                    outlineColor={
                      sepaItem?.status === "inWork" ||
                      sepaItem?.status === "check"
                        ? transformHexToRgba(
                            appConfig.secondaryColor || appConfig.mainColor,
                            "0.4"
                          )
                        : appConfig.secondaryColor || appConfig.mainColor
                    }
                    textColor={
                      sepaItem?.status === "inWork" ||
                      sepaItem?.status === "check"
                        ? transformHexToRgba(
                            appConfig.secondaryColor || appConfig.mainColor,
                            "0.4"
                          )
                        : appConfig.secondaryColor || appConfig.mainColor
                    }
                    disabled={
                      sepaItem?.status === "inWork" ||
                      sepaItem?.status === "check"
                    }
                    marginRight="20"
                    onClick={() => {
                      if (itemId) {
                        dispatch(
                          updateStatusById({
                            id: itemId,
                            status:
                              sepaItem?.payerType ===
                              "abweichendenZahlungsempfänder"
                                ? "check"
                                : "inWork",
                          })
                        );
                      }
                    }}
                  />
                ) : null}
                {
                  <Button
                    text="Akzeptieren"
                    width="200"
                    isOutlined
                    outlineColor={
                      sepaItem?.status === "accepted"
                        ? transformHexToRgba(appConfig.mainColor, "0.4")
                        : appConfig.mainColor
                    }
                    textColor={
                      sepaItem?.status === "accepted"
                        ? transformHexToRgba(appConfig.mainColor, "0.4")
                        : appConfig.mainColor
                    }
                    disabled={sepaItem?.status === "accepted"}
                    marginRight="20"
                    onClick={() => {
                      if (itemId) {
                        dispatch(
                          updateStatusById({
                            id: itemId,
                            status: "accepted",
                          })
                        );
                      }
                    }}
                  />
                }
                <Button
                  text="Stornieren"
                  width="200"
                  backgroundColor="white"
                  isOutlined
                  outlineColor={
                    sepaItem?.status === "declined"
                      ? transformHexToRgba("#ff0000", "0.4")
                      : "red"
                  }
                  textColor={
                    sepaItem?.status === "declined"
                      ? transformHexToRgba("#ff0000", "0.4")
                      : "red"
                  }
                  disabled={sepaItem?.status === "declined"}
                  marginRight="20"
                  onClick={() => {
                    if (itemId) {
                      dispatch(
                        updateStatusById({
                          id: itemId,
                          status: "declined",
                        })
                      );
                    }
                  }}
                />
              </div>
            </>
          )}
        </div>
      </AdminContentWrapper>
      {isAdminLoaderActive && <Loader />}
    </div>
  );
};

export default AdminSepaById;
